@import 'variables';
@import 'a-afonts';

.section-contatti{
  ::selection{
      background: $date-color;
      color:$basic-color;
  }
  .top-block-contatti{
    margin-bottom: 90px;
    @media #{$tablet-portrait}{
      margin-bottom: 0;
    }
    
  }
  .contacts{
    margin-bottom: 90px;
    @media #{$tablet-portrait}{
      margin-bottom: 0;
    }
    }
  .info-artist-list{
    p{
      @media #{$phone}{
        border-top: 1px solid #ccc;
        padding: 20px 20px 10px;
        text-align: center;
      }
    }
  }
}

.cta-side-block{
    min-height: 200px;
    @media #{$tablet-bs}{
      float: left;
      width: 50%;
      padding: 10px;
    }
    @media #{$phone}{
      float:none;
      width: 100%;
    }
    .grey-btn{
      padding: 5px 15px;
      background: url(../images/button-submit.png) no-repeat center;
      background-size: cover;
      height: 58px;
      width: 58px;
      border: 0 none;
      cursor: pointer;
      text-indent: 100px;
      float: right;
    }
  p{
    color: #9a9a9a;
    font-weight: 500;
    font-size: 15px;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.contact-form{
    margin-bottom: 160px;
    padding-right: 5%;
    @media #{$tablet-bs}{
      margin-bottom: 0;
      padding: 0;
    }
    h4{
      font-size: 18px;
    }
    textarea, input, button, a, a:hover {
      outline: none;
      text-decoration: none;
      display: block;
      width: 100%;
      height: 72px;
      text-indent: 20px;
      background-color: #dfdfdf;
      border: 0 none;
      margin-bottom: 38px;
      @media #{$tablet-portrait}{
        margin-bottom: 8px;
        height: 52px;
      }
    }
    textarea{
      min-height: 384px;
      padding: 20px 0;
      margin: 0;
      position:relative;
    }
    input[type="button"] {
      padding: 5px 15px;
      background: url('../images/button-submit.png') no-repeat center;
      background-size: cover;
      height: 58px;
      width: 58px;
      border: 0 none;
      cursor: pointer;
      margin-top: -67px;
      position:relative;
      z-index: 2;
      float: right;
      // position: absolute;
      // bottom: 151px;
      // right: 40px;
      text-indent: 100px;
      // @media #{$mobile}{
      //   position:relative;
      //   bottom:auto;
      //   right:auto;
      //   float:right;
      // }
    }
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $primary;
      font-weight: 600;
    }
    ::-moz-placeholder { /* Firefox 19+ */
       color: $primary;
        font-weight: 600;
    }
    :-ms-input-placeholder { /* IE 10+ */
       color: $primary;
        font-weight: 600;
    }
    :-moz-placeholder { /* Firefox 18- */
       color: $primary;
       font-weight: 600;
    }
}

.form-horizontal{
  .form-control{
      outline: none;
      text-decoration: none;
      display: block;
      width: 100%;
      height: 72px;
      text-indent: 20px;
      background-color: #dfdfdf;
      border: 0 none;
      margin-bottom: 38px;
  }
}
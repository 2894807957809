.cont-share{
    margin-bottom: 50px;
    padding-top: 10px;
    span{
        display: inline-block;
        width: 22px;
        height: 16px;
        -webkit-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 15px;
    }
    .st_facebook_custom{
        background-image: url('../images/ic-fb-grey.png');
    }
    .st_youtube_custom{
        background-image: url('../images/ic_youtube-grey.png');
        width: 20px;
    }
    .st_twitter_custom{
        background-image: url('../images/ic_twitter_grey.png');}
    .st_googleplus_custom{
        background-image: url('../images/ic_gplus-grey.png');}
    .st_sharethis_custom{
        background-image: url('../images/ic-share-grey.png');}

}

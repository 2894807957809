@import 'variables';
@import 'a-afonts';

.progetti-block{
    padding: 40px;
    @media #{$tablet-landscape}{
      padding: 0;
    }
    .block-text{
      padding: 10px;
      background-color: $aliceblue;
      h6{
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
      }
      h3{
        font-weight: 100;
        margin-bottom: 20px;
        font-size: 38px;
        color: $blue;
        .my-arrows{
          img{
            margin-top: -10px;
          }
        }
      }
      p{
        font-size: 15px;
        color:$date-color;
      }
    }
    
    figure{
      overflow: hidden;
      img{
        transition: 1s ease;
        display: block;
        transform:scale(1,1);
        width: 100%;
        &:hover{
          transform:scale(1.5,1.5);
        }
      }
    }
}
.section-progects{
  .row{
    .col-md-4:last-child{
      @media #{$tablet-bs}{
        display: none;
      }
    }
  }
  
}

.section-statuto{
  ::selection{
    background-color:$violet;
    color:$basic-color;
  }

}
  
.section-accordion{
    margin-bottom: 20px;
    
    .panel-default > .panel-heading + .panel-collapse > .panel-body {
        border-top-color: transparent;
    }


    .panel-heading{
      padding: 0 0 0 10px;
      h3{
        @media #{$tablet-landscape}{
          font-size:20px;
        }
        @media #{$mobile}{
          font-size:13px;
        }
      }
    }
    .panel-default{
      border: 0;
    }
    .panel{
      box-shadow: none;
    }
    .panel-group{
      .panel{
        margin-bottom: 40px;
      }
    }
    .panel-heading h3 a{
      position:relative;
      display: block;
      padding: 25px 90px 25px 25px;
      margin-bottom: 75px;
      @media #{$mobile}{
        min-height: 40px;
      }
      &:after{
        display: block;
        position: absolute;
        right: 11px;
        top: 0;
        bottom: 0;
        background-image: url(../images/ic-minus-big-gv.png);
        background-position: center right;
        background-repeat: no-repeat;
        background-size: contain !important;
        content: "";
        -webkit-transition: all 500ms ease;
        transition: all 500ms ease;
        cursor: pointer;
        width: 80px;
        
        transform:rotate(45deg);
      }
      &.collapsed{
         &:after{
           background-image: url(../images/ic-plus-big-gv.jpg);
           transform:rotate(0deg);
         }
      }
    }
    a.panel-internal{
      background-image: url(../images/ic-minus-little-gv.png);
      background-position: left center;
      background-repeat: no-repeat;
      display: inline-block;
      text-indent: 65px;
      background-size: 50px;
      transition: background .5s ease;
      margin-bottom: 30px;
      @media #{$mobile}{
        -webkit-background-size: 30px;
        background-size: 30px;
        text-indent: 35px;
      }
      &.collapsed{
      background-image: url(../images/ic-plus-little-gv.png);
      }
    }
    .panel-body{
      padding: 0;
      h4{
        max-width: 100%!important;
      }
      p{
        font-size: 20px;
        padding: 0;
        @media #{$mobile}{
          font-size: 15px;
        }
        &.txt-article{
          font-size: 36px;
          padding: 0!important;
          margin: 0!important;
          @media #{$mobile}{
            font-size: 20px;
          }
        }
      }
    }
}

.section-accordion{
  .panel-body{
    padding: 0;
    p{
      padding: 0 0 0 40px;
    }
  }
  .panel-collapse{
    .panel-internal{
      p.txt-article{
        margin: 0;
        padding: 0;
      }
      h4{
        padding: 0;
        margin: 0 0 10px;
       
        @media #{$mobile}{
          padding-left: 33px;
          text-indent: 0;
        }
      }
    }
  }
}

.searchMobile{
    @media(min-width:768px){
        display: none;
    }
}
::placeholder{
    font-family:$primary-font;
}
.mobile-search{
    height: 0;
    width: 100%;
    overflow: hidden;
    position:relative;
    //position: absolute;
    //top: 71px;
    //left: 0;
    //right: 0;
    z-index: 99;
    -webkit-transition: height 400ms ease;
    -moz-transition: height 400ms ease;
    -ms-transition: height 400ms ease;
    -o-transition: height 400ms ease;
    transition: height 400ms ease;
    font-family:$primary-font;
    .padd{
        padding: 10px;
        background: $basic-color;
        input[type="search"]{
            background-color: $secondary;
            height: 40px;
            border-radius: 25px;
            width:100%;
            border:0 none;
            text-indent:10px;
            background-repeat: no-repeat;
            background-position: 98% center;
    font-family:$primary-font;
        }
    }
    &.open-search{
        height: 70px;
    }
}
@import 'variables';
@import 'a-afonts';


.my-home-carousel{
    background-color: $secondary;
    overflow: hidden;
    margin-bottom: 50px;
    .glyphicon{
        color:$primary;
    }
    .carousel-inner{
      .item{
        .padd{
          padding: 5% 0;
           img{
              max-width: 65%;
              width: 100%;
              display: block;
              margin:0 auto;
          } 
          .carousel-caption{
            bottom: 35px;
            &.first-slide{
              opacity: 0;
              transition:all 1s ease;
              transform:translate(-100%, 0); 
            }
            &.second-slide{
               transform:translate(100%, 0);
               opacity: 0;
               transition:all 1s ease;
            }
            &.third-slide{
               transform:translate(0, -100%);
               opacity: 0;
              transition:all 1s ease;
            }
          }
        }
        &.active{
          .carousel-caption{
            &.first-slide, &.second-slide, &.third-slide{
              opacity: 1;
              transform:translate(0,0); 
            }
          }
        }
      }
      .carousel-caption{
        text-shadow: none!important;
        h1{
          margin-right: -100px;
          margin-bottom: 3%;
          @media #{$tablet-portrait}{
              margin-right: -120px;
          }
          @media #{$tablet-portrait}{
            margin-right: 0;
            padding: 0;
            margin: 0;
            min-width: 240px;
          }
          .mark-text{
            background-color:$secondary;
            line-height: 150%;
            display: inline;
            box-shadow: 10px 0 0 $secondary, -10px 0 0 $secondary;
          }
          text-align: right;
        }
      }
    }
}
/* -- */
.generic-box.box-size{
  min-height:410px;
}
.carousel-control.right, .carousel-control.left {
    background:transparent;
}
.top-news{
    border: 20px solid $green;
    @media #{$tablet-landscape}{
       border: 10px solid $green;
    }
    /*h2, p{
      @media #{$desktop}{
        max-height: 160px;
        overflow: hidden;
      }
    }*/
}



.generic-box{
  &.little-color-box{
      .shape-yellow{
        max-width: 294px;
        background-color:$orange;
        padding:15px;
        color:$primary;
        bottom: 50px;
        left: 25px;
        position:absolute;
        @media #{$tablet-bs}{
          max-width:195px;
          bottom: 45px;
          left: 20px;
        }
        
        span.view-more{
          position: absolute;
          bottom: -19px;
          right: 8px;
          height: 40px;
          width: 40px;
          background-color:$secondary;
          -ms-transform: rotate(45deg); /* IE 9 */
          -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
          transform: rotate(45deg);
          &:hover{
             &:after{
              background-image:url('../images/ic_arrow_back.png');
              content:'';
              position:absolute;
              width:40px;
              height:40px;
              background-repeat: no-repeat;
              background-position: center;
              -ms-transform: rotate(-45deg); /* IE 9 */
              -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
              transform: rotate(-45deg);
            }
          }
          &:after{
            background-image:url('../images/ic_plus_black.png');
            content:'';
            position:absolute;
            width:40px;
            height:40px;
            background-repeat: no-repeat;
            background-position: center;
            -ms-transform: rotate(45deg); /* IE 9 */
            -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
            transform: rotate(45deg);
            transition: all .2s ease-out;
          }
        }
      }
      .padd{
          position: absolute !important;
          background-size:cover;
          background-position:center center;
        }
        &.block-project-bg{
          @media #{$mobile}{
            min-height: 260px !important;
          }
        }
  }
  &.underline-title{
    h2{
      border-bottom: 20px solid $violet;
      padding-bottom: 10px;
      margin-bottom: 15px;
      @media #{$tablet-portrait}{
        border-bottom: 10px solid $violet;
      }
    }
  }
}

.section-activities{
  .col-xs-4{
    @media #{$phone}{
      width: 100%;
    }
  }
  .padd{
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 240px;
      padding-right: 10px;
      @media #{$desktop}{
        padding: 5px;
      }
      @media #{$tablet-portrait}{
          min-height: 120px;
      }
      @media #{$phone}{
        display: flex;
        align-items: center;
        flex-direction: row;
      }
      .my-arrows{
        display: inline-block;
      }
      .ic-activity{
        &:hover{
            opacity: 0.8;
            text-decoration: none;
            // aggiungere animazione 
        }
        img{
          @media #{$phone}{
            width: 56px;
            max-width:100%; 
          }
        }
      }
      h4{
        padding-left: 10px;
        @media #{$phone}{
          text-align: left;
        }
      }
      a{
        font-family:$primary-font;
        text-align: right;
        font-weight: 600;
        color:$primary;
        font-size: 36px;
        &:hover{
          text-decoration: none;
          opacity: 0.9;
          color:$primary;
        }
        @media #{$desktop}{
          font-weight: 500;
          font-size: 24px;
          line-height: 150%;
        }
      }
  }
}

.see-all{
  display: flex;
  align-items: center;
  justify-content: center;
  .cont-link{
      border-bottom: 20px solid $blue;
      @media #{$desktop}{
          border-bottom: 10px solid $blue;
      }
      a{
      text-align: right;
      font-weight: 300;
      color:$primary;
      font-size: 36px;
      font-family:$primary-font;
      @media #{$desktop}{
        font-size: 24px;
      }
        @media #{$tablet-portrait}{
          font-size: 17px;
          line-height: 26px;
          padding: 0;
          margin: 0; 
        }
      }
      span{
        img{
          @media #{$tablet-portrait}{
            width: 10px; 
          }
        }
      }
  }
}


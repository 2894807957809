
header{
    position:relative;
    .navbar-toggle {
        background: #fff;
        z-index: 999;
        margin-top: 0;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0,-50%);
        left: auto;
        margin: 0;
        padding: 0;
    }
    .cont-nav.navbar-collapse.collapse{
      @media (min-width: 768px){
        display: block !important;
        vertical-align: middle;
        z-index: 999;
        position: relative;
        margin-right: 30px;
      }
      nav{
        @media (min-width: 768px){
          display: block;
          width: 100%;
        }
        &> ul{
          display: flex;
          flex-wrap:nowrap;
          flex-direction:row;
          justify-content:space-around;
          align-items:center;
        }
      }
      
    }
    .cont-header{
      p{
        font-size: 16px;
      }
      .text-right {
        @media #{$tablet-landscape}{
           text-align: center; 
        }
      }
      .padd{
        padding-top: 25px;
         @media (min-width:1170px){
           padding-top: 30px;
         } 
      }
      .cont-logo{
        h1{
          font-size: initial;
          img{
              padding: 15px 0;
              width: 100%;
              max-width:281px;
              @media #{$mobile}{
                max-width: 190px;
              }
          }
        }
      }
    }
     ul{
      padding:0;
      margin:0;
      list-style:none;
      text-align:right;
        li{
          display:inline-block;
          list-style:none;
          margin: 0 10px;
          vertical-align: top;
          font-weight: 600;
          font-family:$primary-font;
          padding: 20px 0;
          @media #{$tablet-portrait}{
            margin: 0 6px;
          }
          a{
            text-decoration: none;
            color:#000;
          }
        }
        // &.main-menu >li{
        //   &:nth-child(1){
        //     &>a:hover{
        //       color:$violet;
        //     }
        //   }
        //   &:nth-child(2){
        //     &>a:hover{
        //       color:$dark-violet;
        //     }
        //   }
        //   &:nth-child(3){
        //     &>a:hover{
        //       color:$orange;
        //     }
        //   }
        //   &:nth-child(4){
        //     &>a:hover{
        //       color:$pink;
        //     }
        //   }
        //   &:nth-child(5){
        //     &>a:hover{
        //       color:$green;
        //     }
        //   }
        //   &:nth-child(6){
        //     &>a:hover{
        //       color:$blue;
        //     }
        //   }
        //   &:nth-child(7){
        //     &>a:hover{
        //       color:$date-color;
        //     }
        //   }
        // }
        .submenu{
          display: none;
          position:absolute;
          top:48px;
          left:50%;
          min-width: 250px;
          background:$secondary;
          z-index: 10;
          transform:translate(-50%, 0);
          @media (min-width:1024px){
            top: 80px !important;
          }
          @media (min-width:1170px){
            top: 97px !important;
          }
          &.multi-voice{
            left: 0;
            transform:translate(0, 0);
            right: 0;
          }
          .second-level{
            display: inline-block;
            .third-level{
              display: inline-block;
              list-style: none;
              margin: 0 auto;
              vertical-align: top;
              font-weight: 600;
              font-family: "AvenirNext";
              a{
                display: block!important;
              }
            }
          }
        }
      } 
    }
.main-menu > li:hover{
  .submenu{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    text-align: left;
    padding: 30px;
  }
  
}
.main-menu > li.hasMultiLevel{
  position:static;
}

.main-menu > li+li:hover{
  .submenu{
    display: block;
  }
  
}
.main-menu > li:hover{
  .submenu.multi-voice{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    text-align: left;
    padding: 30px;
  }
}
.main-menu > li + li{
    position: relative;
}
.main-menu ul{
  right:auto;
}

.second-level.submenu li{
  display: block;
  padding: 0;
  text-align: center;
  margin:0 0 5px;
}

.second-level{
  .third-level{
    width: 100%;
    a{
      display: block;
    }
  }
}

.navbar-toggle{
    background: $basic-color;
    z-index: 999;
    margin-top: 40px;
    span{
      background:$date-color;
      height: 3px;
      display: block;
      width: 40px;
      margin: 7px;
      border-radius: 2px;
    }
}


.main-menu{
     .gray-txt{
         a:hover{
             color:$grey;
         }
         .title-menu{
             color:$grey;
         }
         ul li a:hover{
             color:$grey;
         }
         &.active{
             &>a{
                 color:$grey; 
             }
         }
     }
     .dark-violet-txt{
         .title-menu{
             color:$dark-violet;
         }
         a:hover{
             color:$dark-violet;
         }
         ul li a:hover{
             color:$dark-violet;
         }
         &.active{
             &>a{
                 color:$dark-violet; 
             }
         }
     }
     .violet-txt{
         .title-menu{
             color:$violet;
         }
         a:hover{
             color:$violet;
         }
         ul li a:hover{
             color:$violet;
         }
         &.active{
             &>a{
                 color:$violet; 
             }
         }
     }
     .blue-txt{
         .title-menu{
             color:$blue;
         }
         a:hover{
             color:$blue;
         }
         ul li a:hover{
             color:$blue;
         }
         &.active{
             &>a{
                 color:$blue; 
             }
         }
     }
     .orange-txt{
         .title-menu{
             color:$orange;
         }
         a:hover{
             color:$orange;
         }
         ul li a:hover{
             color:$orange;
         }
         &.active{
             &>a{
                 color:$orange; 
             }
         }
     }
     .pink-txt{
         .title-menu{
             color:$pink;
         }
         a:hover{
             color:$pink;
         }
         ul li a:hover{
             color:$pink;
         }
         &.active{
             &>a{
                 color:$pink; 
             }
         }
     }
     .green-txt{
         .title-menu{
             color:$green;
         }
         a:hover{
             color:$green;
         }
         ul li a:hover{
             color:$green;
         }
         &.active{
             &>a{
                 color:$green; 
             }
         }
     }
}



ul.second-level.submenu{
  &.violet-txt{
    li a:hover{
      color: $violet; 
    }
  }
  &.dark-violet-txt{
    li a:hover{
      color:$dark-violet; 
    }
  }
  &.orange-txt{
    li a:hover{
      color: $orange; 
    }
  }
  &.pink-txt{
    li a:hover{
      color: $pink; 
    }
  }
  &.blue-txt{
    li a:hover{
      color: $blue; 
    }
  }
  &.green-txt{
    li a:hover{
      color: $green; 
    }
  }
  &.grey-txt{
    li a:hover{
      color: $date-color; 
    }
  }
}
.second-level{
  &:after{
    content: "\e114";
    position: absolute;
    top: -35px;
    left: 0;
    margin-left: 0;
    width: 20px;
    height: 20px;
    font-size: 20px;
    display: block;
    font-family: 'Glyphicons Halflings';
    color: #000;
    font-style: normal;
    font-weight: 400;
    right: 0;
    text-align: center;
    width: 100%;
    height: 35px;
    line-height: 38px;
    text-indent: 3px;
    @media (min-width:1170px){
      content: "\e114";
      position: absolute;
      top: -53px;
      left: 0;
      margin-left: 0;
      width: 20px;
      height: 20px;
      font-size: 20px;
      display: block;
      font-family: 'Glyphicons Halflings';
      color: #000;
      font-style: normal;
      font-weight: 400;
      right: 0;
      text-align: center;
      width: 100%;
      height: 55px;
      line-height: 55px;
      text-indent: 3px;
    }
  }
  &.multi-voice{
    &:after{
      text-align: left;
      text-indent: 8%;
    }
  }
}
header ul .submenu {
    top: 75px;
}

header ul li a {
  @media (min-width:1280px){
    font-size: 18px;
  }
}
//media
//$site-width: "only screen and (max-width:1600px)";
$big-desktop: "only screen and (max-width:1270px)";
$desktop: "only screen and (max-width:1400px)";//1199
$tablet-landscape:"only screen and (max-width:1024px)";
$tablet-portrait:"only screen and (max-width:768px)";
$minMobile:"only screen and (min-width:769px)";
$tablet-bs:"only screen and (max-width:991px)";
$minIpad:"only screen and (min-width:768px)";
$menu:"only screen and (max-width:1180px)";
$stepFixResolution :"(min-width:769px) and (max-width:990px)";

//without height in javascript
$nojs:"only screen and (max-width:1023px)";
$minDesktop:"only screen and (min-width:1280px)";
$mobileUp:"only screen and (min-width:768px)";

$mobile: "only screen and (max-width:767px)";
$phone: "only screen and (max-width:480px)";
$iphone: "only screen and (max-width:320px)";
$tablet:"only screen and (min-width:480px) and (max-width:768px)";

//colors
$basic-color:#fff;
$primary:#000; // text titles color
$secondary:#f2f2f2; // bg box grey color     
$date-color:#939597; // date color

$pink:#E82D54;
$blue:#498DAE;
$orange:#F59D00;
$dark-violet:#2B4596;
$violet:#9562A5;
$green:#74CA7B; 
$aliceblue:#ecf3f6;
$grey:#939597;
// fonts

$primary-font: 'AvenirNext';
$secondary-font: 'Oswald';

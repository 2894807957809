.search-results{
  span.no-results-found{
    font-size: 40px;
    color: #ccc;
    font-weight: 100;
    margin: 20px 0 60px;
    display: block;
  }
  
  .blocklist-prev{
      margin-bottom: 40px;
  }
}

.ricercaResult{
    font-size: 25px;
    color: $date-color;
    margin-bottom: 20px;
    font-weight: 200;
    font-style: italic;
}
.label-category{
  span{
      padding: 5px 10px;
      text-transform: capitalize;
      border-radius: 3px;
      margin: 0 0 10px 0;
      display: inline-block;
      color: #fff;
      font-size: 16px;
      font-weight: 200;
      letter-spacing: 2px;
      font-style: italic;
  }
}
.newsletter-form-cnt{
  padding: 20px;
  margin-bottom: 50px;
  input[type="checkbox"]{
    margin:4px 5px 0;
  }
  .description{
    font-family: "AvenirNext";
    font-size: 30px;
  }
  h5{
    padding-top: 5px;
    margin-bottom: 20px;
    display: inline-block;
    padding-left: 10px;
    font-weight: 300;
  }
  .privacy_policy{
    font-size: 10px;
  }
  .form-item-checkbox .description{
    font-family: "AvenirNext";
    font-size: 11px;
  }
  .form-group{
    margin-bottom: 5px;
  }
  .form-item.form-item-textfield.form-group{
    position:relative;
    input[type="submit"]{
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      height: 87px;
      background-image: url(../images/ic-arrow-right.png);
      background-repeat: no-repeat;
      background-position: center;
      border: 0 none;
      border-radius: 0;
      text-indent: 200px;
      overflow: hidden;
    }
  }
  input[type="email"], input[type="text"]{
    font-family:$primary-font;
    border-bottom: 20px solid $blue;
    margin: 0 0 20px;
    padding: 0;
    width: 100%;
    border-left: 0 none;
    border-top: 0 none;
    border-right: 0 none;
    vertical-align: top;
    display: inline-block;
    line-height: 290%;
    font-size: 30px;
    font-weight: 200;
    @media #{$desktop}{
        font-size: 19px;
    }
    &::placeholder{
      font-size: 40px;
    }
  }
  .bottom-newsl{
     input[type="submit"]{
      width: 10%;
      display: inline-block;
      height: 50px;
      vertical-align: top;
      float: right;
      background: transparent;
      border: 0 none;
    } 
    p{
      float:left;
    }
  }

   h4{
      border-bottom: 20px solid $blue;
      margin: 20px 0;
      padding: 20px 0;
      @media #{$mobile}{
        border-bottom: 10px solid $blue;
        margin: 10px 0;
        padding: 10px 0;
      }
   }
  @media #{$mobile}{
    padding: 0 15px;
  }
  .form-newsletter-subscription{
    height: 120px;
    overflow: hidden;
    -webkit-transition: height 500ms ease;
    -moz-transition: height 500ms ease;
    -ms-transition: height 500ms ease;
    -o-transition: height 500ms ease;
    transition: height 500ms ease;
    input[type="email"]{
        height: 50px;
        background: $basic-color;
        width: 85%;
        border:0 none;
        font-size: 21px;
        display: inline-block;
        vertical-align: top;
        text-indent: 10px;
        @media #{$mobile}{
          width: 60%;
        }
      }
      input[type="submit"]{
        width: 15%;
        height: 50px;
        display: inline-block;
        vertical-align: top;
        background: $pink;
        font-size: 25px;
        color: $basic-color;
        border:0 none;
        @media #{$mobile}{
          width: 40%;
        }
      }
      label{
        display: block;
        padding-top: 10px;
        font-weight: 300;
        font-size: 18px;
        input[type="checkbox"]{
          margin-right: 10px;
        }
      }
    .padd{
      padding: 10px;
      background: #ebebeb;
    }
  }
  .cta-open-form{
    display: block;
    p{
      width: 90%;
    }
  }
  &.expanded-form{
    .form-newsletter-subscription{
      height: 120px;
    }
  }
  
  ::placeholder{
    @media #{$mobile}{
      font-size: 24px !important;
    }
  }
}

.newsletter-form-cnt{
    padding: 30px 55px 55px;
    background-color: #f2f2f2;
}
@import 'variables';

/* prova menu mobile */
.menuOpen, .menuClose {
  top: 20px;
  right: 0px;
}
ul .navbar-toggle{
  z-index: 9999;
}
.menu-mobile {
  top: 0px;
  width: 0;
  left: auto;
  overflow: auto;
  right: 0;
  background:$basic-color;
  bottom: 0px;
  height: 100%;
  position: fixed;
  padding-bottom: 80px;
  transition: all .3s ease-in-out;
  z-index: 999999;
  bottom: 0;
  overflow-y: auto
  
  &a{
    margin:5px;
    display: inline-block;
  }
  .cont-share{
    text-align: center;
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
      ul{
      display: inline-block;
      padding:0;
      margin: 0;
      li{
        list-style-type: none;
        display: inline;
        margin: 0;
        a{
            margin: 0;
            padding: 0;
          &:hover{
            opacity: 0.9;
          }
          span{
            img{
              height: 20px;
            }
          }
        }
      }
    }
  }
  ul{
    list-style: none;
    margin: 70px 0 20px 0;
    border-top: 1px solid $basic-color;
    padding: 25px 0;
    li{
      float:none;
      clear:both;
      padding-left: 20px;
      display: block;
      margin: 0 20px;
      a{
        color:$primary;
        text-decoration: none;
        font-family:$primary-font;
        font-size: 16px;
        margin-bottom: 10px;
        display: block;
        float: left;
        text-align: left;
        padding-right: 20px;
        cursor: pointer;
      }
      a+a{
        float:right;
        background: url('../images/arrow-menu-enter.png')no-repeat center right;
        -webkit-background-size: 15px !important;
        background-size: 15px !important;
        display: block;
        width: 60px;
        height: 20px;
      }
    }
  }
  .fixedCloseMenu{
    background-image: url('../images/exit-white.png');
    background-size: 25px;
    left: 0;
    top: 10px;
    width: 50px;
  }
}

.menu-mobile-second-level, .menu-mobile-third-level, .menu-mobile-fourth-level{
  background: $secondary;
  ul{
    li{
      &.prev-voice {
        color:$secondary;
        text-decoration: none;
        font-family: $primary-font;
        font-size: 16px;
        display: block;
        text-align: left;
        padding-bottom: 20px;
        padding-right: 0;
        background: url("../images/arrow-cta-down-menu.png") no-repeat right bottom;
        margin-bottom: 15px;
      }
      a{
        background: none;
        padding-right: 0;
        text-transform: none;
      }
    }
  }
}
.active-sl, .menu-mobile-opened {
  width: 85% !important;
  transition: all .3s ease-in-out;
}
.active-tl, .menu-mobile-opened {
  width: 85% !important;
  transition: all .3s ease-in-out;
}
.active-ftl, .menu-mobile-opened {
  width: 85% !important;
  transition: all .3s ease-in-out;
}
.internal-header{
  
}
.menu-mobile-second-level{
  li{
    a{
      font-size:16px !important;
    }
  }
}
.menu-mobile-third-level{
  background: $pink;
  li{
    a{
      font-size:16px !important;
    }
  }
}
.menu-mobile-fourth-level{
  background: $blue;
  li{
    a{
      font-size:16px !important;
      text-transform: none !important;
    }
  }
}
.textsubcat{
    color: $basic-color;
    text-decoration: none;
    font-size: 16px !important;
    margin-bottom: 10px;
    display: block;
    text-align: left;
    background: none;
    padding-right: 0;
    margin-top: 7px;
}
.prev-voice.subcatvoice{
  font-size:16px !important;
}
@media(max-width:767px){
  .pre-header{
    margin-right:50px;
  }
}
header{
  .toggleFirstLevel{
      width: 50px;
      height: 51px;
      position: absolute;
      top: 45px;
      bottom: 10px;
      left: auto;
      right: 65px;
      z-index: 99;
      background-position: center center;
      padding: 20px;
      background-repeat: no-repeat;
      border-right: 2px solid $basic-color;
      padding: 0;
      img{
        max-width:100%;
      }
      @media #{$minMobile}{
        display: none;
      }
  }
}


.searchMobile{
    width: 65px;
    height: 51px;
    position: absolute;
    top: 19px;
    bottom: 10px;
    right: 0;
    z-index: 99;
    background-image: url(../images/search-lens-mobile.png);
    background-size: 35px;
    background-position: center center;
    padding: 0;
    background-repeat: no-repeat;
    border-left: 2px solid $basic-color;
    @media #{$minMobile}{
      display: none;
    }
    &.toggled{
      background-image: url(../images/close-search.png);
    }
}
.prev-voice{
  color: $primary;
  text-decoration: none;
  font-family: $primary-font;
  font-size:16px;
  display: inline-block;
  padding-left: 10px;
  vertical-align: middle;
}

.menu-mobile.menu-mobile-second-level ul, .menu-mobile.menu-mobile-third-level ul{
    list-style: none;
    margin: 20px 30px 20px 30px;
    border-top: 1px solid $basic-color;
    padding: 25px 0;
}
.title-voice-cat{
  color: $violet;
  text-align:left;
  display: block;
}
.menu-mobile-second-level.menu-mobile ul li{
  padding-left: 0 !important;
}
@import 'variables';

.pagination{
  & > li.active > a, .pagination > li.active > span {
    margin-left: 0;
    border-radius:50%;
    background-color: $primary;
    border-color: $primary;
    color:$basic-color;
  }
  &>li:hover a, &>li a:hover{
      margin-left: 0;
      border-radius:50%;
      background-color: $primary;
      border-color: $primary;
      color:$basic-color;
  }
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover{
      margin-left: 0;
      border-radius:50%;
      background-color: $primary;
      border-color: $primary;
      color:$basic-color;
      cursor:pointer;
}
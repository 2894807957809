@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Bold.eot');
  src: url('../fonts/AvenirNext-Bold.woff2') format('woff2'),
       url('../fonts/AvenirNext-Bold.woff') format('woff'),
       url('../fonts/AvenirNext-Bold.ttf') format('truetype'),
       url('../fonts/AvenirNext-Bold.svg#AvenirNext-Bold') format('svg'),
       url('../fonts/AvenirNext-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-BoldItalic.eot');
  src: url('../fonts/AvenirNext-BoldItalic.woff2') format('woff2'),
       url('../fonts/AvenirNext-BoldItalic.woff') format('woff'),
       url('../fonts/AvenirNext-BoldItalic.ttf') format('truetype'),
       url('../fonts/AvenirNext-BoldItalic.svg#AvenirNext-BoldItalic') format('svg'),
       url('../fonts/AvenirNext-BoldItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-DemiBold.eot');
  src: url('../fonts/AvenirNext-DemiBold.woff2') format('woff2'),
       url('../fonts/AvenirNext-DemiBold.woff') format('woff'),
       url('../fonts/AvenirNext-DemiBold.ttf') format('truetype'),
       url('../fonts/AvenirNext-DemiBold.svg#AvenirNext-DemiBold') format('svg'),
       url('../fonts/AvenirNext-DemiBold.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-DemiBoldItalic.eot');
  src: url('../fonts/AvenirNext-DemiBoldItalic.woff2') format('woff2'),
       url('../fonts/AvenirNext-DemiBoldItalic.woff') format('woff'),
       url('../fonts/AvenirNext-DemiBoldItalic.ttf') format('truetype'),
       url('../fonts/AvenirNext-DemiBoldItalic.svg#AvenirNext-DemiBoldItalic') format('svg'),
       url('../fonts/AvenirNext-DemiBoldItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: italic;
}
/*
@font-face {
  font-family: 'AvenirNext-Heavy';
  src: url('../fonts/AvenirNext-Heavy.eot');
  src: url('../fonts/AvenirNext-Heavy.woff2') format('woff2'),
       url('../fonts/AvenirNext-Heavy.woff') format('woff'),
       url('../fonts/AvenirNext-Heavy.ttf') format('truetype'),
       url('../fonts/AvenirNext-Heavy.svg#AvenirNext-Heavy') format('svg'),
       url('../fonts/AvenirNext-Heavy.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AvenirNext-HeavyItalic';
  src: url('../fonts/AvenirNext-HeavyItalic.eot');
  src: url('../fonts/AvenirNext-HeavyItalic.woff2') format('woff2'),
       url('../fonts/AvenirNext-HeavyItalic.woff') format('woff'),
       url('../fonts/AvenirNext-HeavyItalic.ttf') format('truetype'),
       url('../fonts/AvenirNext-HeavyItalic.svg#AvenirNext-HeavyItalic') format('svg'),
       url('../fonts/AvenirNext-HeavyItalic.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
*/
@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Italic.eot');
  src: url('../fonts/AvenirNext-Italic.woff2') format('woff2'),
       url('../fonts/AvenirNext-Italic.woff') format('woff'),
       url('../fonts/AvenirNext-Italic.ttf') format('truetype'),
       url('../fonts/AvenirNext-Italic.svg#AvenirNext-Italic') format('svg'),
       url('../fonts/AvenirNext-Italic.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Medium.eot');
  src: url('../fonts/AvenirNext-Medium.woff2') format('woff2'),
       url('../fonts/AvenirNext-Medium.woff') format('woff'),
       url('../fonts/AvenirNext-Medium.ttf') format('truetype'),
       url('../fonts/AvenirNext-Medium.svg#AvenirNext-Medium') format('svg'),
       url('../fonts/AvenirNext-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-MediumItalic.eot');
  src: url('../fonts/AvenirNext-MediumItalic.woff2') format('woff2'),
       url('../fonts/AvenirNext-MediumItalic.woff') format('woff'),
       url('../fonts/AvenirNext-MediumItalic.ttf') format('truetype'),
       url('../fonts/AvenirNext-MediumItalic.svg#AvenirNext-MediumItalic') format('svg'),
       url('../fonts/AvenirNext-MediumItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Regular.eot');
  src: url('../fonts/AvenirNext-Regular.woff2') format('woff2'),
       url('../fonts/AvenirNext-Regular.woff') format('woff'),
       url('../fonts/AvenirNext-Regular.ttf') format('truetype'),
       url('../fonts/AvenirNext-Regular.svg#AvenirNext-Regular') format('svg'),
       url('../fonts/AvenirNext-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-UltraLight.eot');
  src: url('../fonts/AvenirNext-UltraLight.woff2') format('woff2'),
       url('../fonts/AvenirNext-UltraLight.woff') format('woff'),
       url('../fonts/AvenirNext-UltraLight.ttf') format('truetype'),
       url('../fonts/AvenirNext-UltraLight.svg#AvenirNext-UltraLight') format('svg'),
       url('../fonts/AvenirNext-UltraLight.eot?#iefix') format('embedded-opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-UltraLightItalic.eot');
  src: url('../fonts/AvenirNext-UltraLightItalic.woff2') format('woff2'),
       url('../fonts/AvenirNext-UltraLightItalic.woff') format('woff'),
       url('../fonts/AvenirNext-UltraLightItalic.ttf') format('truetype'),
       url('../fonts/AvenirNext-UltraLightItalic.svg#AvenirNext-UltraLightItalic') format('svg'),
       url('../fonts/AvenirNext-UltraLightItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 100;
  font-style: italic;
}


@import 'variables';
@import 'a-afonts';
@import 'header';
@import 'home';
@import 'chi-siamo';
@import 'news';
@import 'footer';
@import 'search-bar';
@import 'menu-mobile';
@import 'mobile-search';
@import 'contatti';
@import 'animate';
@import 'cont-filter';
@import 'eventi';
@import 'progetti';
@import 'bandi';
@import 'statuto';
@import 'dettaglio';
@import 'newsletter-form';
@import 'ars';
@import 'progetti-block';
@import 'dettaglio-news';
@import 'gallery';
@import 'shares';
@import 'search-results';
@import 'ricerca';
@import 'pagination';

body, html{
    overflow-x:hidden;
    color:$primary;
}
::selection {
    background: $blue;
    color: $basic-color;
}
.pagination > li > a{
    color: $primary;
    text-decoration: none;
    background-color: transparent;
    border: 0 none;
    font-weight: 700;
}
.container-dettaglio{
  .generica{
    .link-cta{
        color:$primary;
        font-size: 20px;
        display: block;
        font-weight: 500;
        font-family:$primary-font;
        &:hover{
            color:$dark-violet;
        }
    }
  }
}


/* modifica generale bs */


.row {
	margin-left: -10px;
	margin-right: -10px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
	padding-left: 10px;
	padding-right: 10px;
}

/* end modifica generale bs */
textarea, input, button, a, a:hover, *:focus, *:active, a{
    outline: none;
    text-decoration: none;
    -webkit-box-shadow: 0;
    -moz-box-shadow: 0;
    box-shadow: 0;
}


a{
    color:$primary;
    &:hover,&:focus, &:active{
        text-decoration: none;
        //opacity: 0.8;
        color:$primary;
    }
}
.container-dettaglio{
    margin: 30px 0;
    ul{
      li{
          font-size: 18px;
          font-family:$primary-font;
          font-weight: 500;
      }
    }
}
a[href^=tel] {
    color: inherit; text-decoration: none;
}

.carousel-control{
    transition:all .8s ease;
}
.my-container{
   max-width:1600px;
   width:100%;
   margin:0 auto;
   @media (min-width:1900px){
       padding: 0 !important;
   }
   @media #{$minMobile}{
       padding: 0 6%;
   }
   @media #{$mobile}{
       padding: 0;
   }
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color:$date-color;
}
::-moz-placeholder { /* Firefox 19+ */
    color:$date-color;
}
:-ms-input-placeholder { /* IE 10+ */
    color:$date-color;
}
:-moz-placeholder { /* Firefox 18- */
     color:$date-color;
}

.video-block{
    h4{
        margin-bottom: 20px;
    }
}

.section-blocks, .section-contatti, .section-news, .section-eventi, .section-progetti, .section-bandi, .section-about, .section-statuto, .section-regolamenti, .section-dettaglio, .section-dettaglio-news{
  @media #{$tablet-portrait}{
    margin: 15px;
  }
}

.top-block-interne{
    .cont-header{
        margin-bottom: 20px;
        text-align: right;
        position: relative;
        top: 0;
        right: 0;
        min-height:300px;
          @media #{$desktop}{
               position:relative;
          }
          @media #{$big-desktop}{
              min-height:250px;
          }
           @media #{$tablet-landscape}{
              min-height:200px;
          }
          @media #{$tablet-bs}{
              min-height:190px;
          }
          @media #{$mobile}{
              min-height:0;
          }
        figure{
            right: 0;
            top: 0;
            position:absolute;
            @media #{$mobile}{
                position:relative;
            }
            img{
               max-width:70vw;
               width: 1115px;
               @media #{$mobile}{
                   max-width: 100%;
               }
               @media #{$mobile}{
                   width: 100%;
               }
            }
        }
    }
}
.container-header{
    position:relative;
    @media #{$tablet-landscape}{
        margin-bottom: 30px;
    }
    .div-bg{
        background-color:$secondary;
        min-height:415px;
        position:absolute;
        z-index: 1;
        width: 100%;
         @media #{$tablet-landscape}{
             min-height:0;
         }
    }
}
.big-header{
    min-height: 710px;
    background-repeat: no-repeat;
    margin-right: 0;
    background-position: top right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: left;
    z-index: 2;
    position: relative;
    padding-top: 10%;
    background-size: 67%;
    @media #{$desktop}{
        min-height: 500px;
    }
    @media #{$tablet-landscape}{
        min-height: 400px;
        -webkit-background-size: cover;
        background-size: cover;
    }
    h1{
        background-color:$secondary;
        display: inline-table;
        padding-left: 20px;
        padding-right: 10px;
        @media #{$tablet-landscape}{
            padding: 10px;
            margin-bottom: 5px;
        }
        img{
            max-width:100%;
            width: 200px;
        }
    }
    .block-subtitle{
        position: absolute;
        background-color: #f2f2f2;
        top: 350px;
        padding: 50px;
        width: 52%;
        max-width: 725px;
        @media #{$desktop}{
            top: 210px;
        }
        @media #{$tablet-landscape}{
            width: 100%;
            bottom: 0;
            position: absolute;
            top: auto;
            padding: 25px;
        }
        h3{
            height:auto;
            font-weight: 400;
            text-transform: none;
        }
    }
}

img {
    max-width: 100%;
}

h1, h2, h3, h4, h5, h6{
    padding: 0;
    margin: 0;
    color:$primary;
}

h2{
    margin-bottom: 55px;
    @media #{$desktop}{
        margin: 45px 0;
    }
    @media (max-width:1170px){
        font-size: 40px;
    }
    @media #{$tablet-portrait}{
        margin:0;
    }
}

h1{ // titolone slider
    font-family:$primary-font;
    font-size: 100px;
    font-weight: 700;
    @media #{$desktop}{
        font-size: 62px;
    }
    @media #{$tablet-landscape}{
        font-size: 50px;
    }
    @media #{$tablet-portrait}{
        font-size: 45px;
    }
    @media #{$phone}{
        font-size: 33px;
    }
    @media #{$iphone}{
        font-size: 28px;
    }
}

h2{ // titoli generici
    font-family: $primary-font;
    font-size: 60px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 30px;
    @media #{$desktop}{
        //font-size: 50px;   
        font-size: 45px;
        margin-bottom: 5px;
    }
    @media #{$stepFixResolution}{
        font-size: 37px;
    }
    @media #{$tablet-portrait}{
        font-size: 35px;
        //font-size: 40px;
    }
    @media #{$mobile}{
        font-size: 45px;
    }
    @media #{$phone}{
        font-size: 30px;
    }   
    @media #{$iphone}{
        font-size: 25px;
    }
}

h3{ // sottotitolo news
    font-family: $secondary-font;
    font-size: 50px;
    text-transform: uppercase;
    line-height: 120%;
    letter-spacing:-1px;
    @media #{$desktop}{
        font-size: 30px;
    }   
    @media #{$stepFixResolution}{
        font-size: 24px;
    }
    @media #{$tablet-portrait}{
        font-size: 22px;
        //font-size: 24px;
    }
    
    @media #{$mobile}{
        font-size: 30px;
    }
    @media #{$iphone}{
        font-size: 22px;
    }
}

h4{
    font-family:$primary-font;
    font-size: 36px;
    font-weight: 700;
    max-width: 420px;
    margin-bottom: 20px;
    @media #{$desktop}{
        font-size: 19px;
    }
}

h5{ // titoletti footer - read more - altre date
    font-family:$primary-font;
    font-size: 16px;
    font-weight: 700;
}

h6{ // date eventi
    font-family: $secondary-font;
    color:$date-color;
    font-size: 24px;
    padding: 10px 0;
    @media #{$desktop}{
        font-size: 16px;
    }
    @media #{$tablet-portrait}{
        font-size: 14px;
    }
     @media #{$mobile}{
        font-size: 17px;
    }
}

p{ // descrizioni
    font-family: $primary-font;
    font-size: 30px;
    @media #{$desktop}{
        font-size: 18px;
    }
    @media #{$stepFixResolution}{
        font-size: 15px;
    }
    @media #{$tablet-portrait}{
        font-size: 14px;
    }
    @media #{$mobile}{
        font-size: 18px;
    }
    strong{
        font-size: 18px;
        font-family:$primary-font;
        font-weight: 700;
        color:$primary;
    }
    
}


.cont-info{
    .padd{
         display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 25%;
        float: left;
        justify-content: center;
        text-align: left;
        margin-bottom: 10px;
        @media #{$tablet-landscape}{
          width: 50%;
          display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: flex-start;
            margin-bottom: 0;
        }
        @media #{$phone}{
          width: 100%;
          justify-content: center;
        }
      span{
       margin-right: 10px;
      }
      img{
          width: 20px;
      }
      p{
        font-size: 18px;
        padding: 0 5px 0 0;
        strong{
          display: block;
        }
      }
    }
}

.bg-orange{
    background-color:$orange;
}
.bg-blue{
    background-color:$blue;
}

.bg-pink{
    background-color:$pink;
}
.pink-txt{
    color:$pink;
}
.blue-txt{
    color:$blue;
}
.violet-txt{
    color:$violet;

}

.bg-violet{
    background-color:$violet;
}

.bg-dark-violet{
    background-color:$dark-violet;
}

.bg-green{
    background-color:$green;
}
.generic-box, .section-activities, .news{
    margin-bottom: 55px;
    @media #{$desktop}{
        margin-bottom: 45px;
    }
    @media #{$tablet-portrait}{
        margin-bottom: 20px;
    }    
}

.generic-box-full{
    min-height: 500px;
    background-color: $secondary;
    padding: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 60px;
    border:20px solid $pink;
    @media #{$tablet-landscape}{
        min-height:0;
        padding: 20px;
        border:10px solid $pink;
    }
}

.cont-blocchetti{
    max-width:70vw;
    width:1100px;
    float:right;
    padding: 0 20px;
    @media #{$tablet-landscape}{
        max-width:100%;
    }
    .cont-info{
        display:flex;
        align-items:center;
        flex-direction: row;
        .padd{
            display: inline-table;
            width: 25%;
            margin:0;
            padding:0 30px 0 0;
            span{
                margin-right: 10px;
                float: left;
            }
        }
    }
}


// VIDEO BLOCK

.video-block{
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin-bottom: 20px;
    iframe{
        width: 100%!important;
        height: 100%!important;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.generic-box{
  &.square-box{
  background-size:cover;
  .padd{
    top: 45px;
    bottom: 45px;
    left: 45px;
    right: 45px;
    @media #{$desktop}{
      top: 30px;
      bottom: 30px;
      left: 30px;
      right: 30px;
    }
    @media #{$tablet-portrait}{
      top: 20px;
      bottom: 20px;
      left: 20px;
      right: 20px;
    }
    @media #{$desktop}{
        position:absolute;
    }
  }
  .shape{
    height: 70%;
    width: 70%;
    -webkit-transform: translate(-50%,-50%) rotate(45deg);
    transform: translate(-50%,-50%) rotate(45deg);
    text-align: center;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    .rotate-box{
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      @media (min-width:420px) and (max-width:767px){
        padding: 50px;
      }
      h2{
        @media (min-width:580px) and (max-width:767px){
            font-size: 60px;
        }
      }
      p{
        color:$primary;
        margin: 0 auto;
        max-width: 210px;
        overflow: hidden;
        height: 62px;
        @media(min-width:768px) and (max-width:1024px){
          max-width: 120px;
        }
        @media (min-width:580px) and (max-width:767px){
            font-size: 20px;
        }
        @media #{$tablet}{
             height:auto;
        }
      }
    }
    h2{
      margin-bottom:5px;
      a{
           color:$basic-color;
      }
    }
    p{
      font-size: 14px;
      @media #{$tablet-portrait}{
        font-size: 12px;
      }
    }
  }
}
}

.little-articles{
    padding: 0 0 40px;
    h4{
        margin-bottom:20px;
        line-height: 130%;
    }
    p{
        font-size: 16px;
        color: $date-color;
        margin-bottom: 20px;
    }
    a{
        display: inline-block;
    }
}

.generic-box{
    background-color: $secondary;
    min-height: 640px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position:relative;
    &.top-news{
        @media #{$mobile}{
            min-height: 0 !important;
            height: auto!important;   
        }
        @media #{$phone}{
            min-height: 350px;
        }
    }
    &.underline-title{
        padding: 30px;
        @media #{$desktop}{
            padding: 85px;
        }
        @media #{$mobile}{
            height: auto;
            min-height: 310px;
            padding: 15px;
        }
        .padd{
            h2{
                span{
                    img{
                        margin-left: 40px;
                        @media #{$desktop}{
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
    .padd{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        -webkit-transition: background 500ms ease;
        -moz-transition: background 500ms ease;
        -ms-transition: background 500ms ease;
        -o-transition: background 500ms ease;
        transition: background 500ms ease;
        transform-origin: center;
        @media #{$mobile}{
            position:relative;
        }
        &.short-box{
            padding: 30px 75px;
            @media (max-width:1170px){
                padding: 30px 60px;
            }
            @media #{$stepFixResolution}{
                padding: 20px;
            }
            h2{
                @media #{$mobile}{
                  margin-bottom: 0;   
                }
            }
            
            @media #{$mobile}{
                
                padding: 20px;
            }
        }
    }
}

.my-arrows{
    display: inline-block;
    img{
      margin-left: 10px;
      @media #{$tablet-landscape}{
        width: 18px;
      }  
      @media #{$tablet-bs}{
        width: 10px;
      } 
    }
}

.carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next, .carousel-control .icon-prev {
    @media #{$tablet-landscape}{
        font-size: 15px;
    }   
}

@media (max-width:768px){
    .menu-toggle{
        display:none !important;
    }
}


.navbar-toggle {
    @media (max-width: 767px){
        display: block;
    }
}

.navbar-collapse{
    padding: 0;
    margin: 0;
}

.animation-fadeInUp, .animation-fadeInRight, .animation-fadeInLeft, .animation-news{
    @media (min-width:1199px){
        opacity: 0;
    }
}
.animated-v{
    opacity:1;
}

.padd-article{
    h2{
        padding: 0 0 10px;
    }
}
.b-crumbs{
    z-index: 4;
    position: relative;
    margin-top: 80px;
    margin-bottom: 20px;
    @media #{$mobile}{
        margin-top: 0;
    }
    h1{
        background-color: $secondary;
        padding: 0 20px;
        display: inline-block;
    }
}
.big-unique-box{
    background-color:$secondary;
    margin-bottom: 40px;
    .padd-article{
        padding: 10%;
    }
    .box-image-left,.box-text-right{
        display: inline-block;
    }
    .box-image-left{
        width: 40%;
    }
    .padd{
        //height: 527px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        overflow: hidden;
        display: block;
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        @media #{$mobile}{
            height:auto;
            min-height: 250px;
        }
        &:hover{
            -webkit-background-size: 120%;
            background-size: 120%;
        }
        figure{
            overflow: hidden;
            img{
                -webkit-transition: 1s ease;
                transition: 1s ease;
                display: block;
                -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
                width: 100%;
                &:hover{
                    -webkit-transform: scale(1.5, 1.5);
                    transform: scale(1.5, 1.5);
                }
            }
        }
    }
}

.box-size, .box-size-height{
  min-height:100px;
}

.cont-link-download{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: space-between;
    margin: 50px 0;
    justify-content: flex-start;
    @media #{$mobile}{
        display: block;
    }
    &:hover{
        strong{
            color:$violet;
        }
    }
    .cont-zip-file{
        // display:flex;
        // align-items:center;
        display: block;
        width: 100%;
        @media #{$minDesktop}{
        }
        @media #{$mobile}{
            max-width: 100%;
        }
    }
    .block-date{

        @media #{$mobile}{
            max-width:100%;
            display: block;
        }
        p{
            text-align: right;
            @media #{$big-desktop}{
                font-size: 18px;
            }
        }
        time,p{
            @media #{$mobile}{
                display: inline-block;
            }
        }
    }
    span,p{
        display: inline-block;
        vertical-align: middle;
    }
    span{
        margin-right: 25px;
        @media #{$mobile}{
            margin-right: 20px;
            max-width: 60px;
        }
    }

    p{
        margin: 0;
        font-size: 38px;
        padding-left: 10px;
        @media #{$big-desktop}{
            font-size: 32px;
        }
        @media #{$nojs}{
            font-size: 24px;
        }
        @media #{$mobile}{
            max-width: 70%;
            font-size: 18px;
        }
        strong{
            font-size: 38px;
            padding-left: 10px;
            @media #{$big-desktop}{
                font-size: 32px;
            }
            @media #{$nojs}{
                font-size: 24px;
            }
            @media #{$mobile}{
                font-size: 18px;
            }
            
              
        }
    }
    .block-date{
        @media #{$mobile}{
            padding-left: 10px;
        }
        p{
            @media #{$mobile}{
                font-size: 14px;
            }
        }
        h6{
            text-align: right;
            @media #{$mobile}{
                font-size: 14px;
            }
        }
        time{
            font-size: 36px;
        }
    }
}

.object-list{
    h3{
        background-color:$secondary;
        padding: 10px;
    }
    .block-date{
        time,p{
            //display: inline-block;
        }
    }
}

.cont-share{
    ul{
      display: inline-block;
      padding: 0 20px 0px 0;
      li{
        list-style-type: none;
        display: inline;
        a{
          &:hover{
            opacity: 0.9;
          }
          span{
              height: 16px;
            img{
              height: 16px;
            }
          }
        }
      }
    }
}
label.error{
    font-size: 14px;
    color:#d91919;
    margin-bottom: 20px;
    font-weight: 200;
    text-transform: uppercase;
    display: block;
}

.toggleFirstLevel{
    &.toggleSecondLevel{
        left:auto;
        right:0;
    }
}
.goback-voice{
    display: inline-block;
    padding: 20px 0 0 20px;
}
.dif-cont{
    @media #{$minIpad}{
    	display: flex;
    	flex-direction: row;
    	flex-wrap: wrap;
    	justify-content: flex-start;
    	align-items: center;
    	align-content: space-between;
    }
}
/* fix e allineamenti per interlinee */
.section-blocks .generic-box.top-news{
    h2 {
        @media (min-width:1025px){
            margin-bottom: 15px;
            font-size: 45px;
            max-width: 400px;
        }
        
    }
    h6{
        @media (min-width:768px){
            margin-bottom: 15px;
        }
    }
    p{
        @media (min-width:768px){
            line-height: 36px;
        }
        @media (max-width:1170px){
            line-height: 28px;
        }
    }
    h3{
        @media (min-width:768px){
            margin-bottom: 20px;
        }
    }
}
.generic-box.underline-title{
    h3{
        font-weight: 400;
        padding-top: 15px;
        margin-bottom: 18px;
    }
    p{
        @media (min-width:768px){
            line-height: 36px;
        }
    }
}
.section-eventi{
    .section-blocks{
        
        .generic-box-full{
            .row{
                @media #{$minDesktop}{
                    display:flex;
                    align-items:center;
                    justify-content:center;
                }
                
            }
        }
    }
}


.cont-link-download span img {
  max-width: 90px;
}

.news .cont-view-all a span img{
    transform: rotate(90deg);
    max-width: 20px;
}
.object-list span.block-date {
    width: 32%;
    text-align: right;
    margin-right: 0;
    p{
        padding-right: 0;
        margin-right: 0;
    }
}

.my-home-carousel{
    .carousel-control.right {
        @media (min-width:768px){
            right: -50px;
        }
    }
    .carousel-control.left {
        @media (min-width:768px){
            left: -50px;
        }
    }
}
/* modifica in primo piano */
.section-blocks .generic-box.top-news.box-size .h2 a{
    max-width: 360px;
    display: block;
}
/* modifiche shape box */
.generic-box.square-box .shape h2{
    font-size: 66px;
    @media (max-width:1170px){
        font-size: 40px !important;
    }
}
.generic-box.square-box .shape p {
    font-size: 16px;
    @media (max-width:1170px){
        font-size: 10px;
    }
}
/* modifiche shape box */

.generic-box.underline-title h2 {
    display: inline-block;
    @media (min-width:1170px){
        font-size: 80px;
    }
    .my-arrows {
        display: inline-block;
        margin-right: -83px;
    }
}
/* freccia section activities */
.section-activities .padd.see-all, .section-rhombus .padd.see-all{
    .cont-link{
        text-align: right;
    }
    .my-arrows-link{
        position:relative;
    }
    .my-arrows {
        position: absolute;
        top: -5px;
        width: 20px;
    }
}
.news .padd{
    min-height: 720px;
}

.cont-link-download .cont-zip-file {
    @media #{$mobile}{
        text-align: center;
    }
    p{
        max-width: 70% !important;
        @media #{$mobile}{
            max-width: 100% !important;
            padding-top: 10px;
        }
        time{
            @media #{$mobile}{
                display: block;
                max-width: 100% !important;
            }
            span{
                @media #{$mobile}{
                    display: block;
                    max-width: 100% !important;
                }
            }
        }
    }
}

.object-list{
    h3{
        @media #{$mobile}{
            text-align: center;
        }
    }
    span.block-date {
        width: 50%;
        @media #{$mobile}{
            width: 100%;
            text-align: center;
        }
    }
}

.cont-link-download .block-date p{
    time, time span{
        @media only screen and (max-width: 1270px){
            font-size: 18px;
        }
    }
}

.section-progetti .section-blocks .generic-box{
    h6{
        margin-bottom: 15px;
    }
    h3 {
        margin-bottom: 15px;
    }
}
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    float: none;
    clear: both;
    li{
        display: inline-block;
        min-width: 40px;
    }
}
.generic-box.underline-title.box-size{
    .short-box{
        article{
            max-width: 700px;
        }
    }
}

.little-block {
    min-height: 170px;
    h4{
        max-width: 100%;
    }
}

.section-progetti .section-blocks .generic-box.top-news-orange.box-size h2{
    max-width: 420px;
}
.section-progetti .section-blocks .generic-box.top-news-orange.box-size h3, .section-progetti .section-blocks .generic-box.top-news-orange.box-size p{
    max-width: 720px;
}

.big-unique-box{
    .padd-article{
        h2{
            display: inline-block;
            .my-arrows{
                margin-right: -40px;
            }
        }
    }
     h3{
         line-height: 150%;
         font-weight: 300;
         max-width: 770px;
     }   
}

/* nasconde menu secondo livello in about */
.section-about{
    .cont-filter{
        display: none !important;
    }
}
/* nasconde menu secondo livello in mobile ovunque */
.cont-filter{
    @media #{$mobile}{
        display: none !important;
    }
}
/* elimina il gradino nei lanci di bandi */
.col-sm-6.col-xs-12.margin-lancio {
    margin-left: -10px;
    margin-right: -10px;
}
.fig-cont .noImage .ic-img{
    bottom: -50px;
}

.list-search{
    .blocklist-prev{
        min-height: 560px;
        background-color: #f7f7f7;
        margin-bottom: 10px;
        padding: 10px;
        figure{
            margin-bottom: 10px;
            height: 250px;
            overflow: hidden;
            max-width: 100%;
            background: #d2d2d2;
            text-align: center;
        }
        h4{
            font-size: 21px;
            height: 50px;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 120%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
        p{
            font-size: 20px;
            font-weight: 200;
        }
        .label-category{
            margin-bottom: 20px;
            .search-lable-news{
                span{
                    margin: 0;
                    &.bg-dark-violet{
                        background-color:#2B4596;
                    }
                }
            }
            .search-lable-evento{
                span{
                    &.bg-orange{
                        background-color:#F59D00;
                    }
                }
            }
            .search-lable-pages{
                span{
                    &.bg-green{
                        background-color:#74CA7B;
                    }
                }
            }
            span{
                &.bg-pink {
                    background-color:#E82D54;
                }
            }
        }
    }
}


.section-dettaglio{
  ::selection{
    background-color:$violet;
    color:$basic-color;
  }
  
  .container-dettaglio{
    margin: 30px 0;
    a{
      font-family: $primary-font;
      font-size: 30px;
      ul{
        li{
            font-size: 20px;
            font-family:$primary-font;
            font-weight: 500;
        }
    }
    @media #{$desktop}{
        font-size: 18px;
    }
    @media #{$stepFixResolution}{
        font-size: 15px;
    }
    @media #{$tablet-portrait}{
        font-size: 14px;
    }
    @media #{$mobile}{
        font-size: 18px;
    }
    strong{
        font-size: 18px;
        font-family:$primary-font;
        font-weight: 700;
        color:$primary;
    }
    }
    .little-block{
      margin: 20px 0;
    }
  }
  
}


@import 'variables';
@import 'a-afonts';

.searchform{
    min-height: 100px;
    margin: 40px 0;
    @media #{$tablet-landscape}{
      padding: 20px;
    }

      input[type="text"], input[type="search"]{
          border-radius: 40px;
          height: 45px;
          border: 0 none;
          background-color:$secondary;
          padding: 0 20px;
          line-height: 0;
          font-size: 20px;
          color:$primary;
          width: 70%;
      }
      input[type="submit"]{
        border-radius: 40px;
        height: 45px;
        width: 115px;
        border: 0 none;
        color: $basic-color;
        font-size: 26px;
        font-weight: 100;
        line-height: 0;
        @media #{$mobile}{
            width: 83px;
            font-size: 20px;
        }
      }

}

.noresults{
    padding: 0 20px 20px;
    min-height: 200px;
    ul{
      li{
          font-family:$primary-font;
          font-size: 18px;
          margin-bottom: 10px;
      }
    }
}
@import 'variables';
@import 'a-afonts';

.section-dettaglio-news{
  .sharethis{
    h6{
      margin-bottom: 20px;
      font-size: 16px;
      color:#000;
      margin: 0;
    }
  }
  .releated-link{
    .sideblock{
      margin-bottom: 20px;
      h6{
          margin-bottom: 20px;
          font-size: 16px;
          color:#000;
          margin: 0;
        }
      
    }
    .padd{
        min-height: 100px;
        max-width: 260px;
        
        
      a{
        font-size: 18px;
        color: $date-color;
        &:hover{
          opacity: 0.8;
        }
      }
    }
  }
  .text-block{
    margin-bottom: 50px;
    padding: 20px 0;
    p{
      font-size: 18px;
      -webkit-column-count: 2; /* Chrome, Safari, Opera */
      -moz-column-count: 2; /* Firefox */
      column-count: 2;
      @media #{$tablet-bs}{
        -webkit-column-count: 1; /* Chrome, Safari, Opera */
        -moz-column-count: 1; /* Firefox */
        column-count: 1;
      }
    }
  }
  .header-dettaglio{
    margin-bottom: 110px;
    background: url(../images/bg-grey.jpg);
    background-position: top center;
    background-repeat: repeat-x;
    @media #{$tablet-landscape}{
      margin-bottom: 0;
    }
    .grey-box{
      padding: 0;
      position: relative;
      width: 80%;
      z-index: 1;
      margin: 0 auto;
    }
    header{
      margin: 0 auto 90px;
      max-width: 790px;
      z-index: 2;
      position: relative;
      top: 30px;
      padding: 0 20px;
      figure{
        img{
          width: 100%;
        }
      }
    }
  }
}

.list-hashtags{
  a{
    font-size: 18px;
    color: $date-color;
  }
}
@import 'variables';
.cont-search-bar{
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    right: 0;
    top: 45px;
    width: 35px;
    @media (max-width:1170px){
      top: 33px;
    }
    @media #{$mobile}{
      display: none;
    }
    &:hover{
      width: 100%;
      z-index: 999;
      @media #{$mobile}{
        max-width: 130px;
      }
    }
}
.search-form .form-group {
  float: right !important;
  transition: all 0.35s, border-radius 0s;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border: 0 none;
  margin-bottom: 0;
  @media #{$tablet-portrait}{
    margin-top: 0;
    right:10px;
  }
}
.search-form .form-group input.form-control {
  padding-right: 20px;
  border: 0 none;
  background:$basic-color;
  box-shadow: none;
  display:block;
  border-radius: 25px;
}
.search-form .form-group:hover input.form-control {
  background:$secondary;
}
.search-form .form-group input.form-control::-webkit-input-placeholder {
  display: none;
}
.search-form .form-group input.form-control:-moz-placeholder {
  /* Firefox 18- */
  display: none;
}
.search-form .form-group input.form-control::-moz-placeholder {
  /* Firefox 19+ */
  display: none;
}
.search-form .form-group input.form-control:-ms-input-placeholder {
  display: none;
}
.search-form .form-group:hover,
.search-form .form-group.hover {
  width: 100%;
  border-radius: 4px 25px 25px 4px;
}
.search-form .form-group span.form-control-feedback {
  position: absolute;
  top: -1px;
  right: -2px;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color:$date-color;
  left: initial;
  font-size: 14px;
}
.cont-search-bar .glyphicon-search:before {
    font-size: 20px;
    font-weight: 100;
}

footer{
  padding:55px;
  background-color: #D1D2D4;
  
  @media #{$desktop}{
    padding: 20px 0;
  }
  h2{
    text-align: center;
    font-size: 76px;
    @media #{$desktop}{
      font-size: 47px;
    }
    @media #{$mobile}{
      font-size: 34px;
    }
  }
  .cont-social{
    text-align: center;
    margin-bottom: 75px;
    ul{
      padding: 0;
      @media #{$tablet-portrait}{
        margin: 0;
        padding: 30px 0;
      }
      li{
        list-style-type: none;      
        display: inline-block;
        margin:20px;
        @media #{$tablet-portrait}{
          margin: 10px;
        }
        img{
          @media #{$desktop}{
              max-height: 20px;
          }
        }
      }
    }
  }
  .footer-block{
    text-align: center;
    .info-content{
      @media #{$mobile}{
       display:none;
        overflow: hidden;
      }
    }
    &.show-info{
      .info-content{
        display:block;
      }
    }
  }
  .padd{
    display: inline-block;
    text-align: left;
    @media #{$phone}{
      text-align: center;
    }
    .info-content{
      @media #{$phone}{
        text-align: center;
      }
    }
    h5{
      font-size: 20px;
      @media #{$mobile}{
        font-size: 12px;
      }
    }
     p{
      font-size: 16px;
      font-family:$primary-font;
      font-weight: 300;
      @media #{$tablet-portrait}{
        font-size: 12px;
      }
    } 
  }
}
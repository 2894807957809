@import 'variables';
.gallery{
    padding: 20px 0;
    margin-left: -10px;
    margin-right: -10px;
    .block-image{
        padding: 0 50px;
    }
    .slick-slide{
        margin: 0 10px;
        img{
            display: block;
            margin: 0 auto;
            width: 100%;
        }
        text-align: center;
    }
    .slick-arrow{
        position: absolute;
        top: 50%;
        bottom: auto;
        background-repeat: no-repeat;
        background-position: center;
        width: 40px;
        overflow: hidden;
        text-indent: 2000em;
        background-color: rgba(255,255,255,0.7);
        border: 0 none;
        z-index: 999;
        height: 70px;
        margin-top: -35px;
        &.slick-prev{
            background-image: url('../images/arrow-left-y.png');
            left: 10px;
        }
        &.slick-next{
            background-image: url('../images/arrow-right-y.png');
            right: 10px;
        }
    }
}

#cboxCurrent, #cboxPrevious, #cboxNext{
    display: none !important;
}
#cboxClose, #cboxClose:hover, #cboxClose:focus, #cboxClose:active {
    position: absolute;
    top: 0;
    right: 0;
    background: url('../images/exit-link.png') no-repeat center;
    width: 50px;
    height: 50px;
    text-indent: -9999px;
}

.colorbox-gallery-news{
    background: rgba(255,255,255,0.9) !important;
    #cboxPrevious, #cboxNext{
        display: block !important;
        position:absolute;
        top: 50%;
        transform:translate(0,-50%);
        height: 60px;
        width: 40px;
        background-position: center;
        background-color: rgba(255,255,255,0.5);
    }
    #cboxPrevious{
        background-image: url("../images/arrow-left-y.png") !important;
        left: 0 !important;
    }
    #cboxNext{
        background-image: url("../images/arrow-right-y.png") !important;
        right: 0 !important;
        left:auto !important;
    }
}
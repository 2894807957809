@import 'variables';
@import 'a-afonts';


.section-bandi{
  overflow: hidden;
  ::selection{
      background: $dark-violet;
      color:$basic-color;
  }
  p{
    font-size: 20px;
    margin-bottom: 80px;
  }
  .section-link{
    .padd{
        min-height: 150px;
        max-width: 430px;
        @media #{$tablet-portrait}{
          min-height: 65px;
          max-width: 250px;
          text-align: center;
          margin: 0 auto;
          padding: 0;
        }
    }
  }
  .see-all{
    .cont-link{
      padding-top: 160px;
      @media #{$mobile}{
        padding-top: 30px;
      }
    }
  }
  .section-rhombus{
    margin-bottom: 80px;
    clear: both;
    padding-top: 30px;
    .color-shape{
      text-align: center;
      height: 65%;
      width: 65%;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg);
      text-align: center;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
        .my-arrows{
          position:absolute;
          top: 50%;
          right: -25px;
          margin-top: -15px;
          height: 30px;
          display: block;
          width: 30px;
          img{
            transform: none;
            vertical-align: top;
            max-height: 100%;
            max-width: 100%;
          }
        }
      .rotate-box{
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        p{
          font-size: 16px;
          margin: 0;
        }
      }

      
      h2{
        margin: 0 0 20px;
        font-size: 60px;
        @media (max-width:1900px){
          font-size: 40px;
        }
        @media #{$desktop}{
          font-size: 30px;
        }
        a{
          color:$basic-color;
        }
        .ic-white-category{
          display: block;
          margin-bottom: 10px;
          img{
            width: 65px;
            @media #{$desktop}{
              width: 50px; 
            }
          }
        }
      }
    }
    .padd{
      background-repeat:no-repeat;
      background-position: center center;
      -webkit-background-size: 100%;
      background-size: 100%;
      transition:background 2s ease ;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      position:relative;
      &:hover{
        -webkit-background-size: 120%;
        background-size: 120%;
      }
    }
  }
}
.text-rombus{
  padding-right: 30px;
  @media (max-width:1900px){
    padding-right: 10px;
    padding-left: 10px;
  }
}


@import 'variables';
@import 'a-afonts';


.section-ars{
  overflow: hidden;
  ::selection{
      background: $blue;
      color:$basic-color;
  }
  h1{
    img{
      @media #{$desktop}{
        width: 130px;
      }
      @media #{$mobile}{
        width: 86px;
        max-width: 100%;
      }
    }
  }
  h2{
    font-family:$secondary-font;
    font-size: 80px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 10px;
    @media #{$desktop}{
        font-size: 45px;
        margin-bottom: 5px;
    }
    @media #{$tablet-portrait}{
        font-size: 35px;
    }
    @media #{$mobile}{
        font-size: 45px;
    }
    @media #{$phone}{
        font-size: 30px;
    }   
  }
  h3{
    font-weight: 400;
    margin-bottom: 30px;
    letter-spacing: -1px;
    @media #{$desktop}{
        font-size: 30px;
    }
    @media #{$tablet-portrait}{
        font-size: 22px;
    }
    @media #{$mobile}{
        font-size: 30px;
    }
  }
  h6{
    font-weight: 400;
    margin-bottom: 30px;
  }
  .shape{
    .rotate-box{
      h2{
        font-size: 60px;
        font-weight: 400;
        text-transform: inherit;
         @media #{$desktop}{
          font-size: 45px;
          margin-bottom: 5px;
        }
        @media #{$tablet-portrait}{
            font-size: 33px;
        }
        @media #{$mobile}{
            font-size: 45px;
        }
        @media #{$phone}{
            font-size: 30px;
        }   
      }
    }
  }
  .big-unique-box{
    .padd-article{
      h2{
        border-bottom:20px solid $blue;
        @media #{$tablet-landscape}{
          border-bottom:10px solid $blue;
        }
      }
    }
    figure{
        overflow: hidden;
        img{
            -webkit-transition: 1s ease;
            transition: 1s ease;
            display: block;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            width: 100%;
            &:hover{
                -webkit-transform: scale(1.5, 1.5);
                transform: scale(1.5, 1.5);
            }
        }
    }
  }
  .top-block-interne{
    margin-bottom: 40px;
    .b-crumbs{
      top: 0;
      margin-top: 0;
      @media #{$mobile}{
        position:relative;
        h1 img{
          width: 100%;
        }
      }
      h1{
        background-color: transparent;
        padding: 0;
        img{
          @media #{$big-desktop}{
            //max-width:300px;
          }
        }
      }
    }
  }
  .big-unique-box .padd-article {
      padding: 4%;
  }
}

@import 'variables';
@import 'a-afonts';


.section-progetti{
  ::selection{
      background: $orange;
      color:$basic-color;
  }
  .section-event-text{
    border: 20px solid $orange;
    @media #{$tablet-landscape}{
      border: 10px solid $orange;
    }
  }
  .top-news-orange {
    border: 20px solid $orange;
    @media #{$tablet-portrait}{
      border: 10px solid $orange;
    }
  }
  .little-articles{
    &.orange-box{
      .padd{
        min-height: 280px;
        padding-top: 70px;
        @media #{$mobile}{
          min-height: 220px;
          padding-top: 10px;
        }
        &:hover{
          h4{
            a{
              color:$orange;
            }
          }
        } 
      }
    }
  }
}

.big-unique-box{
  .padd-article{
    h2{
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom:20px solid $orange;
    }
  }
}
@import 'variables';
@import 'a-afonts';


.cont-filter{
    padding: 0 0 20px;
    margin-bottom: 30px;
    text-align: right;
    @media #{$mobile}{
        margin-bottom: 0;
    }
    &.pink-filter{
        ul{
            li{
                a{
                    font-family:'AvenirNext';
                }
                &:after{
                    background-color: $pink;
                }
            }
        }
    }
    &.green-filter{
        ul{
            li{
                &:after{
                    background-color: $green;
                }
            }
        }
    }
    &.orange-filter{
        ul{
            li{
                &:after{
                    background-color: $orange;
                }
            }
        }
    }
    &.dark-violet-filter{
        ul{
            li{
                &:after{
                    background-color: $dark-violet;
                }
            }
        }
    }
    ul{
      margin: 0;
    //   display: -webkit-box;
    //   display: -ms-flexbox;
    //   display: flex;
      padding: 0;
    //   flex-direction: row;
    //   flex-wrap: nowrap;
    //   text-align: center;
      width: 100%;
    //   justify-content: center;
    //   align-items: center;
      @media #{$mobile}{
          display: block;
      }
      li{
        display: inline-block;
        list-style-type: none;
        font-weight: 600;
        position: relative;
        vertical-align: bottom;
        padding: 15px;
        text-align: center;
        a{
            font-size: 18px;
            font-family:'AvenirNext';
        }
        @media #{$mobile}{
            margin-bottom: 10px;
            display: block;
            max-width: 100%;
            padding: 2px;
        }
        &:hover, &.active{
          &:after{
            width: 100%;
          }
        }
        &:after{
            content: '';
            height: 5px;
            background-color:$blue;
            width: 0;
            position: absolute;
            right: 0;
            left: 0;
            margin: 0 auto;
            display: block;
            -webkit-transition: all .2s ease;
            -o-transition: all .2s ease;
            transition: all .2s ease;
        }
      }
    }
  }
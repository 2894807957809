@import 'variables';
@import 'a-afonts';


.section-eventi{
  ::selection{
      background: $pink;
      color:$basic-color;
  }
  
  .big-unique-box{
    .padd-article{
    h2{
      border-bottom:20px solid $pink;
       @media #{$tablet-landscape}{
        border-bottom: 10px solid $pink;
      }
    }
  }
  }
  .top-news-pink {
    border: 20px solid $pink;
    @media #{$tablet-portrait}{
      border: 10px solid $pink;
    }
    

  }
  .little-articles{
    &.pink-box{
      .padd{
        min-height: 220px;
        
        &:hover{
          h4{
            a{
              color:$pink;
            }
          }
        } 
      }
    }
  }
  .section-event-text{
    padding: 20px 50px;
    border: 20px solid $pink;
    margin-bottom: 50px;
    @media #{$tablet-portrait}{
      padding: 10px 25px;
      border:10px solid $pink;
    }
  }
  
  &.event-detail{
    .little-articles.pink-box .padd {
        min-height: 150px;
        @media #{$mobile}{
          margin-bottom: 25px;
        }
    }
    h1{
      //font-size: 50px;
      @media #{$tablet-landscape}{
        font-size: 30px;
      }

    }
    .see-all .cont-link {
      border-bottom: 20px solid $pink;
      @media #{$tablet-portrait}{
        border-bottom: 10px solid $pink;
      }
    }
  }
  .big-unique-box .padd-article{
    @media #{$tablet-bs}{
      padding: 4%;
    }
  }
  
  .detail-event{
    .figura-event{
      figure{
        max-width: 420px;
        margin-right: 25px;
        margin-bottom: 25px;
        @media (max-width:767px){
          margin-right: 0;
          max-width: 100%;
        }
        img{
            width: 100%;
        }
      }
      
    }
    p, p *{
      font-size: 15px;
      line-height: 22px;
      font-style: normal;
    }
  }
}


@import 'variables';
@import 'a-afonts';


.news, .releated-section{
  padding: 30px 55px 55px;
  background-color:$secondary;
  position:relative;
  overflow: hidden;
  ::selection{
    background-color:$blue;
    color:$basic-color;
  }
  article{
    .fig-cont{
      video{
        width: 100%;
        padding: 30px 0;
        background-color:$date-color;
      }
    }
  }
  @media #{$desktop}{
    padding: 45px;
  }
  @media #{$tablet-portrait}{
    //margin: 15px;
  }
  .cont-view-all{
    position:absolute;
    right:-5px;
    bottom: 60px;
    @media #{$tablet-portrait}{
      bottom: 100px;
    }
    span {
        position: absolute;
        bottom: -7px;
        right: -40px;
    }
    a{
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        display: block;
        color:#000;
        text-decoration: none;
        &:hover{
          text-decoration: none;
          color: #000;
        }
    }
  }
	.block-news+.block-news+.block-news, .block-releated + .block-releated + .block-releated{
	  @media (max-width:991px){
	    display: none;
	  }
	}
	.block-news+.block-news, .block-releated + .block-releated{
	  @media (max-width:767px){
	    display: none;
	  }
	}

  .block-news, .block-releated{
    .read-more-link{
      display: inline-block;
    }
    .fig-cont{
      position:relative;
      background-color:$date-color;
      &:hover{
        opacity: 1!important;
        .ic-img{
          background: $date-color;
          &:after{
          background-position:center;
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
            background-image: url('../images/arrow-single-white.png');
            background-size: 13px;
          }
        }
      }
    }
    .ic-img{
        background:$green;
        height: 40px;
        width: 40px;
        position: absolute;
        bottom: -19px;
        right: 8px;
        z-index: 5;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        // -webkit-transition: all .2s ease-out;
        // -o-transition: all .2s ease-out;
        // transition: all .2s ease-out;
        &:before, &:after{
          content:'';
          display: inline-block;
          transition:inherit;
          position:absolute;
          background-repeat:no-repeat;
          width: 40px;
          height: 40px;
        }
        &:after{
        -webkit-transition: all .2s ease-out;
        -o-transition: all .2s ease-out;
        transition: all .2s ease-out;
          background-position:center;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          background-image: url('../images/single-plus-white.png');
          background-size: 20px;
        }
      }
    
    .padd{
      overflow: hidden;
      img{
        -webkit-transition: all .2s ease-out;
        -o-transition: all .2s ease-out;
        transition: all .2s ease-out;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        filter: grayscale(100%);
        &:hover{
          -webkit-transform: translateZ(0) scale(1.05);
          transform: translateZ(0) scale(1.05);
          filter: grayscale(0);
        }
      }
    }
    a{
      color:#000;
      text-decoration: none;
      &:hover{
          //opacity: 0.9;
      }
    }
  }  
  
  
  
  @media #{$tablet-portrait}{
    padding: 12px;
  }
  h2{
    text-align: center;
    @media #{$tablet-landscape}{
        margin-bottom: 40px;
    }
  }
  .padd{
    max-width: 320px;
    min-height: 640px;
    margin: 0 auto;
    @media #{$desktop}{
      min-height:550px;
    }
    @media #{$tablet-portrait}{
      max-width:100%;
      padding: 0 20px;
    }
    @media #{$mobile}{
      min-height:0;
    }
    p{
      font-family:$primary-font;
      font-size: 16px;
      color:$date-color;
      padding: 30px 0;
      font-weight: 300;
    }
    figure{
      position:relative;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
  }
}


.news .block-news{
  h4{
    line-height: 130%;
  }
  .padd p{
    padding: 0;
    margin-bottom: 30px;
  }
}
@import 'variables';
@import 'a-afonts';


.section-about{
    margin-bottom: 80px;
    overflow: hidden;
    ::selection{
      background: $violet;
      color:$basic-color;
  }
    p{
        font-size: 20px;
        padding: 20px 30px 20px 0;
        @media #{$tablet-portrait}{
            padding: 0;
        }
    }

    .box-link-side{
        border: 20px solid $violet;
         @media #{$tablet-portrait}{
             border: 10px solid $violet;
         }
        h4{
            padding: 30px;
            max-width: 100%;
             @media #{$tablet-portrait}{
                 padding: 15px;
             }
            a{
                .my-arrows{
                    float: right;
                }
            }
        }
    }
    .my-content .col-sm-7.col-xs-12 .padd{
        padding-top: 80px;
    }
}

.light-txt{
    font-weight: 300;
}
